import { DataTable } from 'src/components/data-table'
import * as React from 'react'
import { IOrder, ISupplierOrder } from 'src/_models/order.model'
import { ColumnDef } from '@tanstack/react-table'
import { formatAuctionDate } from 'src/lib/format-auction'
import currencyFormatter from 'src/components/ui/currency-formatter'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { IRecentUpdate } from 'src/_models/recent-update.model'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from 'src/_store/user.store'

const columns: ColumnDef<IOrder>[] = [
  {
    accessorKey: 'date',
    header: 'Date',
    cell: ({ row }) => {
      const navigate = useNavigate()
      const { user } = useUserStore((state) => ({
        user: state.user,
      }))

      const goToRecentUpdate = (generatedId: string) => {
        if (user?.userType === 'BUYER') {
          navigate(`/buyer/fuel-trading?recent-update=${generatedId}`)
        } else {
          navigate(`/supplier/fuel-trading?recent-update=${generatedId}`)
        }
      }

      return (
        <div
          className="text-center min-w-[160px] text-primary cursor-pointer"
          onClick={() => goToRecentUpdate(row.original.auction.generatedId)}
        >
          {row.original.auction.generatedId}
        </div>
      )
    },
  },
  {
    accessorKey: 'headline',
    header: 'Headline',
    cell: ({ row }) => {
      const { t } = useTranslation('translation')
      return (
        <div className="text-center">
          {row.original.auction.fuelColor.name} {row.original.auction.fuelType.name} -{' '}
          {row.original.auction.fuelPurityLevel.purity} ({t('fuelStateShorthand.' + row.original.auction.fuelState)})
        </div>
      )
    },
  },
  {
    accessorKey: 'weight',
    header: 'Weight',
    cell: ({ row }) => {
      const { t } = useTranslation('translation')
      return (
        <div className="whitespace-nowrap text-center">
          {row.original.auction.fuelWeight} {t('fuelWeightUnit.' + row.original.auction.fuelWeightUnit)}
        </div>
      )
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const { t } = useTranslation('translation')
      return <div className="text-center">{t('fuelTradingStatus.' + row.original.status)}</div>
    },
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    cell: ({ row }) => {
      const getBidAmount = (order: ISupplierOrder) => {
        return order.auction.auctionType === 'AUCTION'
          ? order.winningBid?.bidAmount || 0
          : order.auction.fuelWeight * order.auction.sellingPricePerUnit
      }

      return (
        <div className="whitespace-nowrap text-right">
          {currencyFormatter({
            currency: row.original.auction.sellingCurrency,
            amount: getBidAmount(row.original),
            cents: true,
          })}
        </div>
      )
    },
  },
]

const DashboardOrdersTable = ({ heading, url, orders }: { heading: string; url: string; orders: IOrder[] }) => {
  return (
    <>
      <div className="mb-[15px] flex w-full items-center justify-between">
        <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">{heading}</h2>
        <a href={url} className="text-sm font-bold text-primary">
          View All
        </a>
      </div>
      <DataTable columns={columns} data={orders.splice(0, 6)} headerHidden={true} />
    </>
  )
}

export default DashboardOrdersTable
