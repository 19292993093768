import { Button } from 'src/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import { IOrder } from 'src/_models/order.model'
import OrderDocumentStatus from 'src/components/order/document-status'
import OrderSignatureGuaranteeView from 'src/components/order/signature-guarantee-view'
import TotalPayableAmount from 'src/components/order/total-payable-amount'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import BuyerOrderPaymentMethods from 'src/components/order/buyer-order-payment-methods'
import { useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'src/components/ui/alert-dialog'

const BuyerOrderBuyerPayment = ({ goNext, order }: { goNext: () => void; order: IOrder }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('translation')
  const [showRedirectionAlertUrl, setShowRedirectionAlertUrl] = useState<string | null>(null)
  const partialPayoutToCustomerMutation = useMutation({
    mutationFn: ({ orderId }: { orderId: number }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/buyer/order/payout/${orderId}/partial-buyer-payout`,
        {},
        {
          // responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const partialPayoutToCustomer = () => {
    // order
    partialPayoutToCustomerMutation.mutate(
      { orderId: order.id },
      {
        onSuccess: () => {
          toast({
            title: 'Payment done.',
          })
          queryClient.invalidateQueries()
          goNext()
        },
        onError: (error) => {
          const axiosError = error as AxiosError<{ message: string; redirectUrl?: string }>

          if (axiosError.response?.data?.redirectUrl) {
            setShowRedirectionAlertUrl(axiosError.response?.data?.redirectUrl)
            return
          }

          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(axiosError.response?.data?.message || 'Payment failed.'),
          })
        },
      },
    )
  }

  return (
    <>
      <div className="flex max-h-[calc(90vh-538px)] flex-col overflow-y-scroll px-[30px] ">
        <OrderDocumentStatus order={order} />
        <OrderSignatureGuaranteeView
          order={order}
          type="BUYER"
          title="Buyer Order Guarantee Signature"
          description="Please sign or upload signature to confirm your purchase. After signing you will not be able to rescind the order."
          orderGuaranteeText={order.buyerOrderGuaranteeText}
          orderGuaranteeMediaKey={order.buyerOrderGuaranteeMedia?.fileKey}
        />
        <OrderSignatureGuaranteeView
          order={order}
          type="SUPPLIER"
          title="Supplier Order Guarantee Signature"
          description="Once the Supplier signs, the order will be final and must go through."
          orderGuaranteeText={order.supplierOrderGuaranteeText}
          orderGuaranteeMediaKey={order.supplierOrderGuaranteeMedia?.fileKey}
        />
      </div>
      <div className="max-h-40 px-[30px] pb-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <TotalPayableAmount order={order} />
        <div className="flex gap-5">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button disabled={partialPayoutToCustomerMutation.isLoading} onClick={partialPayoutToCustomer}>
            Deposit Now
          </Button>
          {/* Alert to open url for payment */}
          <AlertDialog
            open={!!showRedirectionAlertUrl}
            onOpenChange={(value) => setShowRedirectionAlertUrl(value ? showRedirectionAlertUrl : null)}
          >
            <AlertDialogContent className="rounded-[40px]">
              <AlertDialogHeader>
                {/*<AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>*/}
                <AlertDialogDescription>
                  You will be redirected to paykeeper to pay for the payment.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction
                  onClick={() => showRedirectionAlertUrl && window.open(showRedirectionAlertUrl, '_self')}
                >
                  Continue
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
    </>
  )
}

export default BuyerOrderBuyerPayment
