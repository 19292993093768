import * as React from 'react'
import DashboardOrdersTable from 'src/pages/fuel-trading/dashboard-orders-table'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { IOrder } from 'src/_models/order.model'
import { useEffect, useState } from 'react'

const DashboardOrdersCompleted = () => {
  const [orders, setOrders] = useState<IOrder[]>()
  const orderCanceledQuery = useQuery({
    queryKey: ['orders', 'orders/cancelled'],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<IOrder[]>('/api/orders/cancelled', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (orderCanceledQuery.data) {
      setOrders(orderCanceledQuery.data.data)
    }
  }, [orderCanceledQuery.data])

  return (
    <div className="w-1/2">
      {orders && (
        <DashboardOrdersTable heading="Cancelled Orders" url="/fuel-trading/cancelled" orders={orders as IOrder[]} />
      )}
    </div>
  )
}

export default DashboardOrdersCompleted
