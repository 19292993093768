import * as React from 'react'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IAuction } from 'src/_models/auction.model'
import AuctionCard from 'src/components/auction/auction-card'
import { useUserStore } from 'src/_store/user.store'

export default function SupplierFuelTradingPastAuction() {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))
  const unfulfilledAuctionQuery = useQuery({
    queryKey: ['supplier-unfulfilled-auctions', 'unfulfilled-auctions', user?.fuelTradingStatePreference],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get('/api/unfulfilled-auctions?limit=5&state=' + user?.fuelTradingStatePreference, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<AxiosResponse<{ auctions: IAuction[] }>>
    },
    enabled: !!user,
  })

  return (
    <section className="mt-10 w-full">
      <div className="container mx-auto bg-transparent ">
        <div className="mb-[15px] flex w-full justify-between">
          <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">Past Auctions</h2>
          <a href="/fuel-trading/unfulfilled-auctions" className="text-sm font-bold text-primary">
            View All
          </a>
        </div>

        <div className="mb-10 grid gap-5 pt-5 lg:grid-cols-3 xl:grid-cols-4  2xl:grid-cols-5">
          {unfulfilledAuctionQuery.data?.data.auctions &&
            unfulfilledAuctionQuery.data?.data.auctions.slice(0, 5).map((auction, index) => {
              return (
                <AuctionCard key={auction.id} auction={auction} className={index === 4 ? 'hidden 2xl:block' : ''} />
              )
            })}
        </div>
      </div>
    </section>
  )
}
