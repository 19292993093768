import { ColumnsOrderProgress } from 'src/components/columns'
import { DataTable } from 'src/components/data-table'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { Dialog, DialogContent } from 'src/components/ui/dialog'
import ModalDocument from 'src/components/common/modalDocument'
import FilterComponent from 'src/components/common/filter'
async function getData(): Promise<ColumnsOrderProgress[]> {
  // Fetch data from your API here.
  return [
    {
      id: '1',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '85%',
      quantity: '250 kg',

      status_order: 'Action required',
      buyer_name: 'Hydrogen Purification Co.',
      winning_bid: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '2',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Green',
      purity_level: '99%',
      quantity: '250 kg',

      status_order: 'Waiting for buyer update',
      buyer_name: 'Hydrogen Purification Co.',
      winning_bid: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '3',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Gray',
      purity_level: '85%',
      quantity: '100 kg',

      status_order: 'Escrow payment received',
      buyer_name: 'Hydrogen Purification Co.',
      winning_bid: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '4',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Gray',
      purity_level: '85%',
      quantity: '100 kg',

      status_order: '20% payment released',
      buyer_name: 'Hydrogen Purification Co.',
      winning_bid: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '5',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '72%',
      quantity: '250 kg',

      status_order: 'Delivered',
      buyer_name: 'Hydrogen Purification Co.',
      winning_bid: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '6',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '72%',
      quantity: '400 kg',

      status_order: 'Payment Completed',
      buyer_name: 'Hydrogen Purification Co.',
      winning_bid: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '7',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '72%',
      quantity: '250 kg',

      status_order: 'Order Completed',
      buyer_name: 'Hydrogen Purification Co.',
      winning_bid: '$ 3,000.00',
      action: 'View Order',
    },
  ]
}
const data = await getData()

const RecTradingOrdersInProgressPage = () => {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [queryParameters] = useSearchParams()
  const parseStep = () => {
    try {
      return parseInt(queryParameters.get('step') || '0')
    } catch (e) {
      return 0
    }
  }
  const [step, setStep] = useState<number>(parseStep())

  let steps = []
  if (queryParameters.has('role') && queryParameters.get('role') === 'SUPPLIER') {
    steps = [
      { title: t('buyerSteppers.step1') },
      { title: t('buyerSteppers.step2') },
      { title: t('buyerSteppers.step3') },
      { title: t('buyerSteppers.step4') },
      { title: t('buyerSteppers.step5') },
      { title: t('buyerSteppers.step6') },
    ]
  } else {
    steps = [
      { title: t('buyerSteppers.step1') },
      { title: t('buyerSteppers.step2') },
      { title: t('buyerSteppers.step3') },
      { title: t('buyerSteppers.step4') },
      { title: t('buyerSteppers.step5') },
      { title: t('buyerSteppers.step6') },
    ]
  }
  const [showModalDocument, setShowModalDocument] = useState<boolean>(false)
  const [showOrderStatusModal, setShowOrderStatusModal] = useState<boolean>(false)

  const columnsOrderProgress: ColumnDef<ColumnsOrderProgress>[] = [
    {
      accessorKey: 'order_date',
      header: 'ORDER DATE',
      cell: ({ row }) => {
        return <div className="text-center">{row.renderValue('order_date')}</div>
      },
    },
    {
      accessorKey: 'order_id',
      header: 'ORDER ID',
      cell: ({ row }) => {
        return <div className="text-center">{row.renderValue('order_id')}</div>
      },
    },
    {
      accessorKey: 'fuel_type',
      header: 'FUEL TYPE',
      cell: ({ row }) => {
        return <div className="text-center">{row.renderValue('fuel_type')}</div>
      },
    },
    {
      accessorKey: 'color',
      header: 'COLOR',
      cell: ({ row }) => {
        return <div className="text-center">{row.renderValue('color')}</div>
      },
    },
    {
      accessorKey: 'purity_level',
      header: 'PURITY LEVEL',
      cell: ({ row }) => {
        return <div className="text-center">{row.renderValue('purity_level')}</div>
      },
    },
    {
      accessorKey: 'quantity',
      header: 'QUANTITY',
      cell: ({ row }) => {
        return <div className="text-center">{row.renderValue('quantity')}</div>
      },
    },
    {
      accessorKey: 'status_order',
      header: 'STATUS OF ORDER',
      cell: ({ row }) => {
        return <div className="text-center">{row.renderValue('status_order')}</div>
      },
    },
    {
      accessorKey: 'buyer_name',
      header: 'Buyer Name',
      cell: ({ row }) => {
        return <div className="text-center">{row.renderValue('buyer_name')}</div>
      },
    },

    {
      accessorKey: 'winning_bid',
      header: 'Winning Bid',
      cell: ({ row }) => {
        return <div className="text-center">{row.renderValue('winning_bid')}</div>
      },
    },
    {
      accessorKey: 'action',
      header: '',
      cell: ({ row }) => {
        // const payment = row.original

        return (
          <a
            onClick={() => setShowOrderStatusModal(true)}
            className="cursor-pointer font-bold text-[#0077B6] underline"
          >
            View&nbsp;Order
          </a>
        )
      },
    },
  ]

  return (
    <>
      <section className="w-full">
        <div className="container mx-auto bg-transparent ">
          <div className="mb-[15px] flex w-full items-center justify-between pt-10 align-middle">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">Orders in Progress</h2>
            <span className="relative text-sm">
              <div className="flex gap-[10px]">
                <button
                  onClick={() => setShowFilter(!showFilter)}
                  className="w-[102px] rounded-[10px] bg-white px-[15px] py-[8px] text-center align-middle text-xs font-medium text-secoundary shadow-md 2xl:text-sm"
                >
                  Filter By
                </button>
                <button className="rounded-[10px] text-center align-middle">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 10 13" fill="none">
                    <path
                      d="M0.5 12.5645H10V11.1527H0.5M10 4.79975H7.28572V0.564453H3.21429V4.79975H0.5L5.25 9.74092L10 4.79975Z"
                      fill="#333333"
                    ></path>
                  </svg>
                </button>
              </div>
              {showFilter && (
                <FilterComponent
                  // className="absolute right-0"
                  onCancel={() => setShowFilter(false)}
                />
              )}
            </span>
          </div>

          <DataTable columns={columnsOrderProgress} data={data} headerHidden={false} />
          <Dialog open={showOrderStatusModal} onOpenChange={(value) => setShowOrderStatusModal(value)}>
            <DialogContent className="mx-auto h-auto max-h-[90vh] w-full max-w-[741px] overflow-y-scroll rounded-[40px] bg-white px-0 py-[30px]">
              {/*<BuyerOrderDialog setShowModalDocument={setShowModalDocument} />*/}
            </DialogContent>
          </Dialog>

          {showModalDocument && (
            <ModalDocument
              onCancel={() => setShowModalDocument(false)}
              onFileSelection={() => {
                setShowModalDocument(false)
              }}
            ></ModalDocument>
          )}
        </div>
      </section>
    </>
  )
}

export default RecTradingOrdersInProgressPage
