import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/components/ui/accordion'
import faqData from 'src/utils/faq-data'
import { useParams } from 'react-router-dom'

const FAQBuyerPage = () => {
  const { slug } = useParams()
  const faqSection = faqData[0].subsections.find((faq) => faq.slug === slug)

  if (!faqSection) {
    return <div>FAQ Section not found</div>
  }

  return (
    <div>
      <Accordion type="single" collapsible className="w-full">
        {faqSection.questions.map((question) => {
          return (
            <AccordionItem
              key={question.id}
              value={question.id.toString()}
              className="mb-5 border-none bg-[#ececec] rounded-2xl"
            >
              <AccordionTrigger className="bg-white px-2.5 rounded-2xl">{question.question}</AccordionTrigger>
              <AccordionContent className="p-5">{question.answer}</AccordionContent>
            </AccordionItem>
          )
        })}
      </Accordion>
    </div>
  )
}

export default FAQBuyerPage
