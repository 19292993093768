import { Button } from 'src/components/ui/button'
import { useNavigate } from 'react-router-dom'

const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <div className="flex min-h-screen flex-col items-center mt-10">
      <h1 className="text-2xl font-bold text-secoundary">404</h1>
      <p className="text-sm font-normal text-secoundary">Page not found</p>
      <Button className="mt-5 w-48" onClick={() => navigate('/')}>
        Go back home
      </Button>
    </div>
  )
}

export default NotFoundPage
