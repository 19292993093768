import * as React from 'react'
import { useEffect, useState } from 'react'
import { ITransaction } from 'src/_models/order.model'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useUserStore } from 'src/_store/user.store'
import { ColumnDef } from '@tanstack/react-table'
import { formatAuctionDate } from 'src/lib/format-auction'
import currencyFormatter from 'src/components/ui/currency-formatter'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { Dialog, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import { DataTable } from 'src/components/data-table'
import OrderDialog from 'src/components/order/order-dialog'

const DashboardSharedRecentTransactions = () => {
  const { t } = useTranslation('translation')
  const [transactions, setTransactions] = useState<ITransaction[]>([])
  const [selectedOrder, setSelectedOrder] = useState<ITransaction | null>(null)
  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })
  const [showOrderStatusModal, setShowOrderStatusModal] = useState<boolean>(false)

  const transactionHistoryQuery = useQuery({
    queryKey: ['transactions'],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ transactions: ITransaction[] }>(`/api/orders/transaction-history?limit=5`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (transactionHistoryQuery.data) {
      setTransactions(transactionHistoryQuery.data.data.transactions.filter((t, index) => index < 5))
    }
  }, [transactionHistoryQuery.data])

  const columnsOrders: ColumnDef<ITransaction>[] = [
    {
      accessorKey: 'createdAt',
      header: 'DATE',
      cell: ({ row }) => {
        return <div className="text-center">{formatAuctionDate(row.original.createdAt)}</div>
      },
    },
    {
      accessorKey: 'auction_type',
      header: 'Auction Type',
      cell: ({ row }) => {
        return <div className="text-center">{t(`auctionType.${row.original.order.auction.auctionType}`)}</div>
      },
    },
    {
      accessorKey: 'userTransactionType',
      header: 'Transaction Type',
      cell: ({ row }) => {
        return <div className="text-center">{t(`userTransactionType.${row.original.userTransactionType}`)}</div>
      },
    },
    {
      accessorKey: 'auction_id',
      header: 'Auction',
      cell: ({ row }) => {
        return (
          <a
            className="cursor-pointer font-bold text-[#0077B6] underline"
            onClick={() => {
              setSelectedOrder(row.original)
              setShowOrderStatusModal(true)
            }}
          >
            {row.original.order.auction.generatedId}
          </a>
        )
      },
    },
    {
      accessorKey: 'buyer_name',
      header: 'Buyer Name',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.order.buyer.companyName}</div>
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: ({ row }) => {
        return (
          <div className="flex justify-end gap-2.5">
            {currencyFormatter({
              currency: row.original.currency,
              amount: row.original.amount,
              cents: true,
            })}
            {row.original.lineItems && row.original.lineItems.length > 0 && (
              <Dialog>
                <DialogTrigger asChild>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path
                      d="M11 7.56445H9V5.56445H11M11 15.5645H9V9.56445H11M10 0.564453C8.68678 0.564453 7.38642 0.823111 6.17317 1.32566C4.95991 1.8282 3.85752 2.5648 2.92893 3.49339C1.05357 5.36875 0 7.91229 0 10.5645C0 13.2166 1.05357 15.7602 2.92893 17.6355C3.85752 18.5641 4.95991 19.3007 6.17317 19.8032C7.38642 20.3058 8.68678 20.5645 10 20.5645C12.6522 20.5645 15.1957 19.5109 17.0711 17.6355C18.9464 15.7602 20 13.2166 20 10.5645C20 9.25123 19.7413 7.95087 19.2388 6.73762C18.7362 5.52436 17.9997 4.42197 17.0711 3.49339C16.1425 2.5648 15.0401 1.8282 13.8268 1.32566C12.6136 0.823111 11.3132 0.564453 10 0.564453Z"
                      fill="#333333"
                    ></path>
                  </svg>
                </DialogTrigger>
                <DialogContent className="w-[422px] rounded-[20px] px-5 py-[30px]">
                  <div className="flex flex-col gap-2.5">
                    {row.original.lineItems.map((lineItem) => {
                      return (
                        <div className="flex flex-row">
                          <div className="w-full">{t(`lineItemType.${lineItem.type}`)}</div>
                          <div className="w-full text-right">
                            {lineItem.type === 'SupplierFeesAmount' && '- '}
                            {currencyFormatter({
                              currency: lineItem.currency,
                              amount: +lineItem.amount,
                              cents: true,
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </DialogContent>
              </Dialog>
            )}
          </div>
        )
      },
    },
  ]

  // Modify to add other columns of breakdown
  const exportCSV = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      filename: 'recent-transactions', // export file name (without .csv)
      decimalSeparator: '.',
      useKeysAsHeaders: true,
    })
    const rowData = transactions.map((row) => ({
      DATE: formatAuctionDate(row.createdAt),
      'Auction Type': t(`auctionType.${row.order.auction.auctionType}`),
      'Transaction Type': row.userTransactionType,
      'Transaction ID': row.id,
      'Buyer Name': row.order.buyer.companyName,
      Amount: t('currency.' + row.currency) + ` ${row.amount / 100}`,
    }))
    const csv = generateCsv(csvConfig)(rowData)
    download(csvConfig)(csv)
  }

  const exportCSVAll = () => {
    if (!transactionHistoryQuery.data) return

    const csvConfig = mkConfig({
      fieldSeparator: ',',
      filename: 'recent-transactions', // export file name (without .csv)
      decimalSeparator: '.',
      useKeysAsHeaders: true,
    })
    const rowData = transactionHistoryQuery.data.data.transactions.map((row) => ({
      DATE: formatAuctionDate(row.createdAt),
      'Auction Type': t(`auctionType.${row.order.auction.auctionType}`),
      'Transaction Type': 'PURCHASE',
      'Transaction ID': row.id,
      'Buyer Name': row.order.buyer.companyName,
      Amount: t('currency.' + row.currency) + ` ${row.amount / 100}`,
    }))
    const csv = generateCsv(csvConfig)(rowData)
    download(csvConfig)(csv)
  }

  return (
    <div className="shadow[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)] flex w-full flex-col rounded-[20px] border-2 border-white bg-[#e9f9ff]  px-5 pt-[13px]">
      <div className="flex justify-between align-middle">
        <div className="flex justify-between align-middle">
          <h2 className="mb-[13px] text-sm font-bold 2xl:text-base">Recent transactions</h2>
        </div>
        <div className="flex gap-2.5">
          <Dialog>
            <DialogTrigger asChild>
              <a className="text-sm font-bold text-primary cursor-pointer">See All</a>
            </DialogTrigger>
            <DialogContent className="mx-auto h-auto max-h-[90vh] w-full max-w-[841px] overflow-y-scroll rounded-[40px] bg-white px-0 py-0">
              <div className="max-h-[90vh] overflow-scroll shadow[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)] flex w-full flex-col rounded-[20px] border-2 border-white bg-[#e9f9ff]  px-5 pt-[13px]">
                <div className="flex justify-between align-middle">
                  <div className="flex justify-between align-middle">
                    <h2 className="mb-[13px] text-sm font-bold 2xl:text-base">Recent transactions</h2>
                  </div>
                  <div className="flex gap-2.5">
                    <Popover>
                      <PopoverTrigger asChild>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M5 20H19V18H5M19 9H15V3H9V9H5L12 16L19 9Z" fill="#333333" />
                        </svg>
                      </PopoverTrigger>
                      <PopoverContent className="w-48" align="end">
                        <div className="cursor-pointer" onClick={exportCSVAll}>
                          Export As CSV
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
                {transactionHistoryQuery.data && (
                  <DataTable
                    columns={columnsOrders}
                    data={transactionHistoryQuery.data.data.transactions}
                    headerHidden={false}
                    className="bg-[#e9f9ff]"
                  />
                )}
              </div>
            </DialogContent>
          </Dialog>
          <Popover>
            <PopoverTrigger asChild>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5 20H19V18H5M19 9H15V3H9V9H5L12 16L19 9Z" fill="#333333" />
              </svg>
            </PopoverTrigger>
            <PopoverContent className="w-48" align="end">
              <div className="cursor-pointer" onClick={exportCSV}>
                Export As CSV
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <DataTable columns={columnsOrders} data={transactions} headerHidden={false} className="bg-[#e9f9ff]" />
      {selectedOrder && (
        <Dialog open={showOrderStatusModal} onOpenChange={(value) => setShowOrderStatusModal(value)}>
          <OrderDialog
            orderId={selectedOrder.order.id}
            setShowOrderStatusModal={(showDialog) => setShowOrderStatusModal(showDialog)}
          />
        </Dialog>
      )}
    </div>
  )
}

export default DashboardSharedRecentTransactions
