import { Dialog, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import currencyFormatter from 'src/components/ui/currency-formatter'
import { IOrder } from 'src/_models/order.model'
import { useUserStore } from 'src/_store/user.store'
import { useEffect, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { Controller, useForm } from 'react-hook-form'
import { Input } from 'src/components/ui/input'
import CreateAuctionOtherFees from 'src/pages/supplier/fuel-trading/create-auction-other-fees'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { IAuction } from 'src/_models/auction.model'
import { Skeleton } from 'src/components/ui/skeleton'
import { useToast } from 'src/components/ui/use-toast'
import { payKeeperUrl } from 'src/config'

type IOrderEditFeesForm = {
  fuelFeesFixed?: number
  fuelFeesPercentage?: number
  auctionOtherFees: {
    label: string
    isPercentage: boolean
    value: number
  }[]
}

const OrderTotalPayableEdit = ({
  auctionId,
  fuelTradingOrderId,
}: {
  auctionId: number
  fuelTradingOrderId: number
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const supplierAuctionQuery = useQuery({
    queryKey: [`supplierAuction-${auctionId}-${new Date()}`],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ auction: IAuction }>(`/api/supplier/auctions/${auctionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  const updateFeesMutation = useMutation({
    mutationFn: (data: IOrderEditFeesForm) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/supplier/order-update-fees/${fuelTradingOrderId}`,
        {
          auction: { ...data },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })
  const { t } = useTranslation('translation')
  const { control, reset, handleSubmit } = useForm<IOrderEditFeesForm>()

  useEffect(() => {
    if (supplierAuctionQuery.data) {
      const auction = supplierAuctionQuery.data.data.auction
      reset({
        fuelFeesPercentage: parseFloat(auction.fuelFeesPercentage || '0'),
        fuelFeesFixed: auction.fuelFeesFixed ? parseFloat(auction.fuelFeesFixed) / 100 : +(auction.fuelFeesFixed || 0),
        auctionOtherFees: auction.auctionOtherFees.map((o) => {
          return {
            ...o,
            value: o.isPercentage ? +o.value : parseInt(o.value) / 100,
          }
        }),
      })
    }
  }, [supplierAuctionQuery.data])

  const updateFeesAction = (data: IOrderEditFeesForm) => {
    updateFeesMutation.mutate(data, {
      onSuccess: (_) => {
        setDialogOpen(false)
        queryClient.invalidateQueries()
        toast({
          title: 'Success',
          description: 'Fess Updated successfully.',
        })
      },
      onError: (err) => {
        const axiosError = err as AxiosError<{ message: string }>
        toast({
          title: 'Error',
          description: t(axiosError.response?.data?.message || 'Fess updation failed.'),
        })
      },
    })
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={(o) => setDialogOpen(o)}>
      <DialogTrigger asChild>
        <Button tabIndex={-1}>Edit Fees</Button>
      </DialogTrigger>
      <DialogContent className="rounded-[20px] px-5 py-[30px]">
        <>
          {supplierAuctionQuery.isLoading && (
            <div className="flex flex-col gap-2.5">
              <div className="mt-5 flex justify-between gap-10">
                <div className="flex w-full flex-col">
                  <Skeleton className="w-1/2 h-6" />
                  <div className="mt-2">
                    <Skeleton className="w-full h-14" />
                  </div>
                </div>
                <div className="flex w-full flex-col">
                  <Skeleton className="w-1/2 h-6" />
                  <div className="mt-2">
                    <Skeleton className="w-full h-10" />
                  </div>
                </div>
              </div>
              <div className="mt-5 flex justify-between gap-10">
                <div className="flex w-full flex-col">
                  <Skeleton className="w-1/2 h-6" />
                  <div className="mt-2">
                    <Skeleton className="w-full h-10" />
                  </div>
                </div>
                <div className="flex w-full flex-col">
                  <Skeleton className="w-1/2 h-6" />
                  <div className="mt-2">
                    <Skeleton className="w-full h-10" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {supplierAuctionQuery.error && <div className="flex flex-col gap-2.5">Something went wrong.</div>}
          {supplierAuctionQuery.data && (
            <div className="flex flex-col gap-2.5">
              <div className="mt-5 flex justify-between gap-10">
                <div className="flex w-full flex-col">
                  <label
                    htmlFor="fuelFeesPercentage"
                    className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
                  >
                    {t('auction.fuelFeesPercentageLabel')}
                  </label>
                  <Controller
                    control={control}
                    name="fuelFeesPercentage"
                    render={({ field, fieldState }) => {
                      return (
                        <div className="mt-2">
                          <Input
                            type="number"
                            id="fuelFeesPercentageLabel"
                            placeholder={t('auction.fuelFeesPercentageLabel')}
                            {...field}
                            onChange={(e) =>
                              e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                            }
                            error={fieldState.error}
                          />
                        </div>
                      )
                    }}
                  />
                </div>
                <div className="flex w-full flex-col">
                  <label
                    htmlFor="fuelFeesFixed"
                    className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
                  >
                    {t('auction.fuelFeesFixedLabel')}
                  </label>
                  <Controller
                    control={control}
                    name="fuelFeesFixed"
                    render={({ field, fieldState }) => {
                      return (
                        <div className="mt-2">
                          <Input
                            type="number"
                            id="fuelFeesFixedLabel"
                            placeholder={t('auction.fuelFeesFixedLabel')}
                            {...field}
                            onChange={(e) =>
                              e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                            }
                            error={fieldState.error}
                          />
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
              {/* @ts-ignore */}
              <CreateAuctionOtherFees control={control} disabled={false} />
              <Button disabled={updateFeesMutation.isLoading} onClick={handleSubmit(updateFeesAction)}>
                Update Fees
              </Button>
            </div>
          )}
        </>
      </DialogContent>
    </Dialog>
  )
}

const OrderTotalPayableAmount = ({ order }: { order: IOrder }) => {
  const { user } = useUserStore((store) => ({
    user: store.user,
  }))
  const partialPaymentDone =
    [
      'BUYER_PAYMENT_COMPLETED',
      'SHIPPING_CONTRACT_UPLOAD',
      'PRODUCT_SHIPPED',
      'SELLER_PARTIAL_PAYMENT',
      'PRODUCT_DELIVERED',
      'DELIVERY_CONFIRMATION',
      'PRODUCT_CONFIRMATION',
      'SELLER_REMAINDER_PAYMENT_PENDING',
    ].indexOf(order.status) !== -1

  const escrowStatus = ['BUYER_PAYMENT_PENDING', 'BUYER_PAYMENT_COMPLETED', 'SHIPPING_CONTRACT_UPLOAD']

  const partialPaymentStatus = [
    'PRODUCT_SHIPPED',
    'DELIVERY_CONFIRMATION',
    'PRODUCT_CONFIRMATION',
    'SELLER_REMAINDER_PAYMENT_PENDING',
  ]

  if (user?.userType === 'SUPPLIER') {
    return (
      <div className="flex w-full flex-col rounded-lg py-5">
        <div className="flex flex-col px-[0px]">
          <div className="flex w-full items-center justify-between gap-[10px]">
            <div className="flex items-center gap-[7px]">
              <span className="text-lg font-bold text-secoundary 2xl:text-xl">
                {order.status !== 'COMPLETED' ? 'Total Payout Amount' : 'Total Amount Received'}
              </span>
              <Dialog>
                <DialogTrigger asChild>
                  <span className="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                      <path
                        d="M11 7.56445H9V5.56445H11M11 15.5645H9V9.56445H11M10 0.564453C8.68678 0.564453 7.38642 0.823111 6.17317 1.32566C4.95991 1.8282 3.85752 2.5648 2.92893 3.49339C1.05357 5.36875 0 7.91229 0 10.5645C0 13.2166 1.05357 15.7602 2.92893 17.6355C3.85752 18.5641 4.95991 19.3007 6.17317 19.8032C7.38642 20.3058 8.68678 20.5645 10 20.5645C12.6522 20.5645 15.1957 19.5109 17.0711 17.6355C18.9464 15.7602 20 13.2166 20 10.5645C20 9.25123 19.7413 7.95087 19.2388 6.73762C18.7362 5.52436 17.9997 4.42197 17.0711 3.49339C16.1425 2.5648 15.0401 1.8282 13.8268 1.32566C12.6136 0.823111 11.3132 0.564453 10 0.564453Z"
                        fill="#333333"
                      />
                    </svg>
                  </span>
                </DialogTrigger>
                <DialogContent className="w-[450px] rounded-[20px] px-5 py-[30px]">
                  <div className="flex flex-col gap-2.5">
                    <div className="flex flex-row">
                      <div className="w-full">Bid Amount</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.bidAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-full">State Taxes</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.stateTaxAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-full">City Taxes</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.cityTaxAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-full">Fuel Fees (Fixed)</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.fuelFeesFixedAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-full">Fuel Fees (Percentage)</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.fuelFeesPercentageAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    {order.fuelTradingOrderOtherFees.map((otherFee) => {
                      return (
                        <div className="flex flex-row" key={otherFee.id}>
                          <div className="w-full">{otherFee.auctionOtherFees.label}</div>
                          <div className="w-full text-right">
                            {currencyFormatter({
                              currency: order.auction.sellingCurrency,
                              amount: otherFee.feesAmount,
                              cents: true,
                            })}
                          </div>
                        </div>
                      )
                    })}
                    <div className="flex flex-row">
                      <div className="w-full">Supplier Fees</div>
                      <div className="w-full text-right">
                        -{' '}
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.supplierFeesAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row" hidden={!order.shippingCost}>
                      <div className="w-full">Shipping</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.shippingCost,
                          cents: true,
                        })}
                      </div>
                    </div>
                    {order.status === 'DOCUMENT_UPLOAD' && (
                      <OrderTotalPayableEdit auctionId={order.auction.id} fuelTradingOrderId={order.id} />
                    )}
                  </div>
                </DialogContent>
              </Dialog>
              {partialPaymentDone ? (
                escrowStatus.includes(order.status) ? (
                  <span className="text-sm">(Auction Amount in Escrow)</span>
                ) : partialPaymentStatus.includes(order.status) ? (
                  <span className="text-sm">{order.adminShipmentSentApprovalAt && '(20% paid to supplier)'}</span>
                ) : (
                  <span className="text-sm">(100% paid to supplier)</span>
                )
              ) : (
                ''
              )}
            </div>
            <div className=" block text-lg font-bold text-secoundary 2xl:text-xl">
              {currencyFormatter({
                currency: order.auction.sellingCurrency,
                amount:
                  +order.totalAmount +
                  (order.shippingCost ? +order.shippingCost : 0) -
                  (order.supplierFeesAmount ? +order.supplierFeesAmount : 0),
                cents: true,
              })}
            </div>
          </div>
          <div>
            {order.paykeeperSupplierLink && ['DISPUTED', 'CANCELLED'].indexOf(order.status) === -1 && (
              <a className=" text-[#0077B6] underline font-bold" href={order.paykeeperSupplierLink} rel="noreferrer">
                Set Payout Account
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col rounded-lg py-5">
      <div className="flex flex-col px-[0px]">
        <div className="flex w-full items-center justify-between gap-[10px]">
          <div className="flex items-center gap-[7px]">
            <span className="text-lg font-bold text-secoundary 2xl:text-xl">
              {order.status !== 'COMPLETED' ? 'Total Payable Amount' : 'Total Amount Paid'}
            </span>
            <Dialog>
              <DialogTrigger asChild>
                <span className="cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path
                      d="M11 7.56445H9V5.56445H11M11 15.5645H9V9.56445H11M10 0.564453C8.68678 0.564453 7.38642 0.823111 6.17317 1.32566C4.95991 1.8282 3.85752 2.5648 2.92893 3.49339C1.05357 5.36875 0 7.91229 0 10.5645C0 13.2166 1.05357 15.7602 2.92893 17.6355C3.85752 18.5641 4.95991 19.3007 6.17317 19.8032C7.38642 20.3058 8.68678 20.5645 10 20.5645C12.6522 20.5645 15.1957 19.5109 17.0711 17.6355C18.9464 15.7602 20 13.2166 20 10.5645C20 9.25123 19.7413 7.95087 19.2388 6.73762C18.7362 5.52436 17.9997 4.42197 17.0711 3.49339C16.1425 2.5648 15.0401 1.8282 13.8268 1.32566C12.6136 0.823111 11.3132 0.564453 10 0.564453Z"
                      fill="#333333"
                    />
                  </svg>
                </span>
              </DialogTrigger>
              <DialogContent className="w-[450px] rounded-[20px] px-5 py-[30px]">
                <div className="flex flex-col gap-2.5">
                  <div className="flex flex-row">
                    <div className="w-full">Bid Amount</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.bidAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-full">State Taxes</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.stateTaxAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-full">City Taxes</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.cityTaxAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-full">Fuel Fees (Fixed)</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.fuelFeesFixedAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-full">Fuel Fees (Percentage)</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.fuelFeesPercentageAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  {order.fuelTradingOrderOtherFees.map((otherFee) => {
                    return (
                      <div className="flex flex-row" key={otherFee.id}>
                        <div className="w-full">{otherFee.auctionOtherFees.label}</div>
                        <div className="w-full text-right">
                          {currencyFormatter({
                            currency: order.auction.sellingCurrency,
                            amount: otherFee.feesAmount,
                            cents: true,
                          })}
                        </div>
                      </div>
                    )
                  })}
                  <div className="flex flex-row" hidden={!order.shippingCost}>
                    <div className="w-full">Shipping</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.shippingCost,
                        cents: true,
                      })}
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            {partialPaymentDone ? (
              escrowStatus.includes(order.status) ? (
                <span className="text-sm">(Auction Amount in Escrow)</span>
              ) : partialPaymentStatus.includes(order.status) ? (
                <span className="text-sm">{order.adminShipmentSentApprovalAt && '(20% paid to supplier)'}</span>
              ) : (
                <span className="text-sm">(100% paid to supplier)</span>
              )
            ) : (
              ''
            )}
          </div>
          <div className=" block text-lg font-bold text-secoundary 2xl:text-xl">
            {currencyFormatter({
              currency: order.auction.sellingCurrency,
              amount: +order.totalAmount + (order.shippingCost ? +order.shippingCost : 0),
              cents: true,
            })}
          </div>
        </div>
        <div className="flex w-full items-center justify-between gap-[10px]">
          {escrowStatus.includes(order.status) ? (
            <a className=" text-[#0077B6] underline font-bold" href={payKeeperUrl} target="_blank" rel="noreferrer">
              Edit Payment Method
            </a>
          ) : (
            <div></div>
          )}
          {order.paykeeperFundingSuccessCallbackAt && order.status === 'BUYER_PAYMENT_PENDING' && (
            <div className="text-orange">Order Payment Pending</div>
          )}
          {order.paykeeperShippingFundingSuccessCallbackAt && order.status === 'SHIPPING_CONTRACT_UPLOAD' && (
            <div className="text-orange">Shipping Payment Pending</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderTotalPayableAmount
