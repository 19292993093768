import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'src/components/ui/card-default'
import { Badge } from 'src/components/ui/badge'
import { format } from 'date-fns'
import { Button } from 'src/components/ui/button'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { CalendarIcon, UserIcon } from 'lucide-react'
import ResolveBtn from 'src/pages/support-requests/resolve-btn'

type ISupportRequest = {
  id: string
  category: string
  issue: string
  description: string
  user: {
    id: string
    firstName: string
    lastName: string
    userType: string
  }
  createdAt: string
  updatedAt: string
  isResolved: boolean
}

const SupportRequestsPage = () => {
  const navigate = useNavigate()
  const supportRequestsQuery = useQuery({
    queryKey: ['supportRequests'],
    queryFn: async () => {
      const token = localStorage.getItem('token')
      return axios.get<{ supportRequests: ISupportRequest[] }>('/api/support-requests', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    retryOnMount: false,
    refetchOnReconnect: false,
  })

  const resolveRequestAction = (id: string) => {
    console.log('Resolve request action', id)
  }

  const supportRequests = supportRequestsQuery.data?.data?.supportRequests ?? []

  return (
    <div className="container mx-auto cursor-pointer space-y-4 p-4">
      <h1 className="mb-6 text-2xl font-bold text-center">Support Requests</h1>
      {supportRequests.map((request) => (
        <Card key={request.id} className="w-1/2 mx-auto" onClick={() => navigate(`/support-requests/${request.id}`)}>
          <CardHeader>
            <div className="flex items-start justify-between">
              <div>
                <CardTitle>
                  {request.category} ({request.issue})
                </CardTitle>
              </div>
              <Badge variant={request.isResolved ? 'default' : 'secondary'}>
                {request.isResolved ? 'Resolved' : 'Unresolved'}
              </Badge>
            </div>
          </CardHeader>
          <CardContent>
            <div className="text-muted-foreground flex items-center justify-between text-sm">
              <div className="flex items-center">
                <CalendarIcon className="mr-2 h-4 w-4" />
                Created: {format(new Date(request.createdAt), 'dd MMM yyyy hh:mm aaa')}
              </div>
              <div className="flex items-center">
                <CalendarIcon className="mr-2 h-4 w-4" />
                Updated: {format(new Date(request.updatedAt), 'dd MMM yyyy hh:mm aaa')}
              </div>
            </div>
          </CardContent>
          {/*{!request.isResolved && (*/}
          {/*  <CardFooter>*/}
          {/*    <ResolveBtn expand="full" supportRequestId={request.id} />*/}
          {/*  </CardFooter>*/}
          {/*)}*/}
        </Card>
      ))}
    </div>
  )
}

export default SupportRequestsPage
