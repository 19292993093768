const faqData = [
  {
    id: 1,
    slug: 'general',
    name: 'General FAQs',
    subsections: [
      {
        id: 1,
        slug: 'platform-faqs',
        name: 'Platform FAQs',
        questions: [
          {
            id: 1,
            question: 'What is the Regeneration Energy (REgen) Platform about?',
            answer:
              'The REgen Platform was specifically created for the Clean Fuels industry (Hydrogen, Ammonia, Methanol and other fuels) by stakeholders from this industry. The goal - to provide an alternative fuel trading and services platform for small-to-large suppliers and buyers to trade with each other. Large trading and brokerage platforms say they are inclusive but primarily cater for the Oil & Gas Majors. These MNCs dictate supply and demand pricing, quantums, and flows. By creating a market for stakeholders outside of the O&G majors, REgen offers a fairer, more transparent, more equitable environment. We currently offer the ability to undertake spot and future commodities trades (with buyers taking delivery of these fuels). We plan to expand from primary commodities trading to: an REC market, speciality Tracking & Tracing Services for Green Fuel producers, Project and Product financing, a Longer-term Offtake Market, and Market Analytics & Insights.',
          },
          {
            id: 2,
            question: 'Why use the REgen Fuel Trading Marketplace?',
            answer:
              'Suppliers use this marketplace to sell inventory batches that they would like to sell immediately (spot) or by a certain point in time (futures). Instead of searching for buyers one-by-one via buyer lists, trade shows, conferences, referrals etc., they can find a selection of verified buyers to choose from via the REgen marketplace. Buyers similarly, can find verified, credible sellers for one-off or multiple purchases via REgen. In order to facilitate trust in a trustless ecosystem, REgen offers Escrow services for every auction on the platform; buyers pay into the Escrow account and Sellers receive payments based on milestones and deliverables. In short - we automate trust at every step of the auction to order process, to ensure smooth transactions and delivery of goods. \n' +
              '' +
              'In essence, the REgeneration Fuel Trading Marketplace provides end-to-end support for the purchase and sale of fuels. We enable suppliers to easily auction and sell fuel, as well as undertake contracting and delivery of the fuel to buyers. Buyers are able to easily purchase or bid for auctions that are created by suppliers - and can track everything from auction to delivery.',
          },
          {
            id: 3,
            question: 'Why the REgen Fuel Trading Marketplace at all?',
            answer:
              'REgen speeds up the Supplier/Buyer sourcing process while pre-verifying stakeholders to ensure that every participant in our platform is both trustworthy and creditworthy. Users do not need to know Finance or the "How-To\'s" or how a trading/brokerage desk operates - if you have ever used a stock trading platform (Fidelity, TD Ameritrade, Robinhood) or an Amazon/eBay, you will be able to easily use REgen. We also cut down payments from the 30-60-90 days payments window to 14 days or less - which means supplier orders get fast-tracked and buyers can track and sign/verify delivery sooner rather than later.',
          },
          {
            id: 4,
            question: 'What does the REgen Fuel Trading Marketplace sell?',
            answer:
              'We enable verified Suppliers to sell liquified Hydrogen, Ammonia and Methanol fuel of various colors and grades/purity levels. We will be expanding this to include Sustainable Aviation Fuel (SAF), Ethanol, and other fuel products in the near future.',
          },
          {
            id: 5,
            question: 'How does the REgen Fuel Trading Marketplace work?',
            answer:
              'Verified Suppliers will create new auction cards for their fuels using the "Create Auction" box in their Fuel Trading Main Dashboard. Once cards have been created, they are listed as "Live Auctions" for all Suppliers and Buyers to view on their Dashboard. Suppliers can list their card to be either an "Auction" (which means they set a base floor price, similar to an eBay auction) or as a "Purchase Now" card (which means a buyer does not have to bid but can purchase outright at stated price). Buyers can then click these cards to learn more about the fuel on sale, the Supplier, where the supply is located, fuel cost breakdown (including taxes, fees, insurance etc) and other bidders. Once a card has been "Purchased" either through an outright purchase or a winning bid, it will then turn into an Order. Buyers who have purchased this order are obligated to follow through on their purchase, pending contracting. For more information on the Auction and Orders process, please refer to the section on "Auctions and Orders".',
          },
        ],
      },
      {
        id: 2,
        slug: 'new-user-registration-and-logging-in',
        name: 'New User Registration & Logging In',
        questions: [
          {
            id: 1,
            question: 'How can I register for an account?',
            answer:
              'To register for a Buyer account, please visit beta.regenenergy.io and click on the "Sign Up" button within the Login Now box. It will then prompt you to create a "Buyer" or "Supplier" account - please click the "Buyer" button to proceed. You will then reach a "Buyer Details" white box - please fill that box with your Name, Email Id as well as password of your choosing. Then click the "Verify Now" button. It will then prompt you to an OTP verification section. For this Beta Test mode of our platform, that OTP code is 123456. \n' +
              '' +
              'Once OTP has been verified, please set up your account following our prompts as well as provide requested company documents in order for us to verify your business (KYC). Kindly do note that once signed up, it will take 24-48 hours for us to verify and turn on your account. We will email you once your account is ready to go. ',
          },
          {
            id: 2,
            question: 'How can I login?',
            answer:
              'Once your account is activated, you can go back to beta.regenenergy.io and typer in your email and password and click the "Login" button to proceed to your account. If you are unable to login due to a password error, please click on the "Forget Password?" link and we will email you a code to reset back on beta.regenenergy.io and you can follow the prompts to reset your password.',
          },
        ],
      },
      {
        id: 3,
        slug: 'my-buyer-dashboard',
        name: 'My (Buyer) Dashboard',
        questions: [
          {
            id: 3,
            question: 'Where is the Main Buyer Dashboard?',
            answer:
              'Once you have logged into the system, you will be directed to "My Dashboard" where you can see an overview of your spends, quick-links to Auctions/Orders that need your attention, Platform & Recent Updates, as well as Recent Transactions.',
          },
        ],
      },
    ],
  },
]

export default faqData
