import { Button } from 'src/components/ui/button'
import * as React from 'react'
import { payKeeperUrl } from 'src/config'

const DashboardSharedPayment = () => {
  return (
    <div className="shadow[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)] rounded-[20px] border-2 border-white bg-primary p-5 2xl:p-[30px]">
      <div className="mb-[10px] flex w-full flex-col 2xl:mb-5">
        <div className="mb-[10px] text-sm font-bold leading-[17px] text-white 2xl:text-lg 2xl:leading-[21px]">
          Manage your Paykeeper account
        </div>
      </div>

      <Button
        onClick={() => window.open(payKeeperUrl, '_blank')}
        className="line-height bg-[#023047] font-medium text-white 2xl:py-3 2xl:text-sm"
      >
        Go to Paykeeper
      </Button>
    </div>
  )
}

export default DashboardSharedPayment
