import { Button } from 'src/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'

const ResolveBtn = ({ supportRequestId, expand }: { supportRequestId: string; expand: 'normal' | 'full' }) => {
  const queryClient = useQueryClient()
  const resolveServiceRequestMutation = useMutation({
    mutationFn: (serviceRequestId: string) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/support-requests/${serviceRequestId}/resolve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const resolveRequestAction = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    event.preventDefault()
    resolveServiceRequestMutation.mutate(id, {
      onSuccess: () => {
        toast({
          title: 'Service request resolved successfully',
        })
        queryClient.invalidateQueries({ queryKey: ['supportRequests'] })
      },
      onError: (err) => {
        const axiosError = err as AxiosError<{ message: string }>
        toast({ title: axiosError.response?.data?.message ?? 'Something went wrong', variant: 'destructive' })
      },
    })
  }

  return (
    <Button variant="default" expand={expand} onClick={(event) => resolveRequestAction(event, supportRequestId)}>
      Resolve
    </Button>
  )
}

export default ResolveBtn
