const DisputedOverlayText = ({ order }: { order: any }) => {
  if (order.status !== 'DISPUTED') {
    return null
  }

  return (
    <div className="absolute w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50 rounded-b-[40px]">
      <div className="bg-danger text-white p-4 rounded font-bold font-archivo">
        This order is disputed. Please contact the buyer or REgen to resolve the dispute.
      </div>
    </div>
  )
}

export default DisputedOverlayText
