import { useEffect, useState } from 'react'
import UnfulfilledAuctionsTable from 'src/pages/fuel-trading/unfulfilled-auctions/unfulfilled-auctions-table'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { IAuctionWithFuelTradingOrders } from 'src/_models/auction.model'
import { useSearchParams } from 'react-router-dom'

const FuelTradingUnfulfilledAuctionsPage = () => {
  const [auctions, setAuctions] = useState<IAuctionWithFuelTradingOrders[]>([])
  const [searchParams, _] = useSearchParams()

  const params = searchParams.toString()
  const orderInProgressQuery = useQuery({
    queryKey: ['auctions', 'auctions/unfulfilled-auctions', params],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ auctions: IAuctionWithFuelTradingOrders[] }>(`/api/unfulfilled-auctions?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (orderInProgressQuery.data) {
      setAuctions(orderInProgressQuery.data.data.auctions)
    }
  }, [orderInProgressQuery.data])

  return (
    <>
      <section className="w-full">
        <UnfulfilledAuctionsTable heading="Unfulfilled Auctions" auctions={auctions} />
      </section>
    </>
  )
}

export default FuelTradingUnfulfilledAuctionsPage
