import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { getAuthLayout, getDefaultLayout } from './components/layout'
import LoginPage from './pages/auth/login'
import ForgetPasswordPage from 'src/pages/auth/forget-password'
import NotProtected from 'src/components/not-protected'
import SignUpPage from './pages/auth/signup'
import Protected from 'src/components/protected'
import DashboardPage from 'src/pages/dashboard'
import MyWatchlistPage from 'src/pages/my-watchlist'
import SupplierFuelTradingDashboard from 'src/pages/supplier/fuel-trading'
import BuyerFuelTradingDashboard from 'src/pages/buyer/fuel-trading'
import BuyerGreenTracingDashboard from './pages/green-tracing'
import BuyerRecTradingDashboard from './pages/rec-trading'
import AuctionsSupplier from './pages/supplier/auctions'
import LiveAuctionsPage from 'src/pages/live-auctions'
import BuyerFuelTradingMyAuctionsPage from './pages/buyer/fuel-trading/my-auctions/index'
import BuyerRecLiveProjects from './pages/rec-trading/live-rec-projects'
import BuyerRecMyWatchlist from './pages/rec-trading/my-watchlist'
import BuyerRecProjectPrevPurchased from './pages/rec-trading/project-prev-purchased'
import RecTradingOrdersInProgressPage from './pages/rec-trading/orders-in-progress'
import RecTransactionHistory from './pages/rec-trading/transaction-history'
import RecOrderCompleted from './pages/rec-trading/order-completed'
import BuyerGreenTracingDashboardWatchList from './pages/green-tracing/my-watchlist'
import FuelTradingCompletedOrdersPage from 'src/pages/fuel-trading/completed-orders'
import FuelTradingOrdersInProgressPage from 'src/pages/fuel-trading/orders-in-progress'
import FuelTradingOrdersCancelledPage from 'src/pages/fuel-trading/cancelled'
import FuelTradingOrdersDisputedPage from 'src/pages/fuel-trading/disputed'
import ProfilePage from 'src/pages/profile'
import FuelTradingTransactionHistoryPage from 'src/pages/fuel-trading/transaction-history'
import FuelTradingUnfulfilledAuctionsPage from './pages/fuel-trading/unfulfilled-auctions'
import BuyerOrderFunded from 'src/pages/buyer-order-funded'
import BuyerShippingFunded from 'src/pages/buyer-shipping-funded'
import FAQPage from 'src/pages/faq'
import FAQDetailPage from 'src/pages/faq/detail'
import NotFoundPage from 'src/pages/not-found-page'
import SupportRequestsPage from 'src/pages/support-requests'
import SupportRequestDetailPage from 'src/pages/support-requests/[id]'

export const router = createBrowserRouter([
  // {
  //   path: '/',
  //   element: <Protected>{getDefaultLayout(<Home />)}</Protected>,
  // },
  {
    path: '/auth/login',
    element: <NotProtected>{getAuthLayout(<LoginPage />)}</NotProtected>,
  },
  {
    path: '/auth/signup',
    element: <NotProtected>{getAuthLayout(<SignUpPage />)}</NotProtected>,
  },
  {
    path: '/auth/forget-password',
    element: <NotProtected>{getAuthLayout(<ForgetPasswordPage />)}</NotProtected>,
  },
  {
    path: '/',
    element: <Protected>{getDefaultLayout(<DashboardPage />)}</Protected>,
  },
  {
    path: '/profile',
    element: <Protected>{getDefaultLayout(<ProfilePage />)}</Protected>,
  },
  // {
  //   path: '/profile-buyer',
  //   element: <Protected>{getDefaultLayout(<ProfileBuyerPage />)}</Protected>,
  // },
  // {
  //   path: '/profile-supplier',
  //   element: <Protected>{getDefaultLayout(<ProfileSupplierPage />)}</Protected>,
  // },
  {
    path: '/my-watchlist',
    element: <Protected>{getDefaultLayout(<MyWatchlistPage />)}</Protected>,
  },
  {
    path: '/live-auctions',
    element: <Protected>{getDefaultLayout(<LiveAuctionsPage />)}</Protected>,
  },
  {
    path: '/buyer/fuel-trading',
    element: <Protected>{getDefaultLayout(<BuyerFuelTradingDashboard />)}</Protected>,
  },
  {
    path: '/fuel-trading/transaction-history',
    element: <Protected>{getDefaultLayout(<FuelTradingTransactionHistoryPage />)}</Protected>,
  },
  {
    path: '/fuel-trading/unfulfilled-auctions',
    element: <Protected>{getDefaultLayout(<FuelTradingUnfulfilledAuctionsPage />)}</Protected>,
  },

  {
    path: '/green-tracing',
    element: <Protected>{getDefaultLayout(<BuyerGreenTracingDashboard />)}</Protected>,
  },
  {
    path: '/green-tracing/my-watchlist',
    element: <Protected>{getDefaultLayout(<BuyerGreenTracingDashboardWatchList />)}</Protected>,
  },
  {
    path: '/rec-trading',
    element: <Protected>{getDefaultLayout(<BuyerRecTradingDashboard />)}</Protected>,
  },
  {
    path: '/rec-trading/live-rec-projects',
    element: <Protected>{getDefaultLayout(<BuyerRecLiveProjects />)}</Protected>,
  },
  {
    path: '/rec-trading/my-watchlist',
    element: <Protected>{getDefaultLayout(<BuyerRecMyWatchlist />)}</Protected>,
  },
  {
    path: '/rec-trading/project-prev-purchased',
    element: <Protected>{getDefaultLayout(<BuyerRecProjectPrevPurchased />)}</Protected>,
  },
  {
    path: '/rec-trading/orders-in-progress',
    element: <Protected>{getDefaultLayout(<RecTradingOrdersInProgressPage />)}</Protected>,
  },
  {
    path: '/rec-trading/order-completed',
    element: <Protected>{getDefaultLayout(<RecOrderCompleted />)}</Protected>,
  },
  {
    path: '/rec-trading/transaction-history',
    element: <Protected>{getDefaultLayout(<RecTransactionHistory />)}</Protected>,
  },
  {
    path: '/buyer/my-auctions',
    element: <Protected>{getDefaultLayout(<BuyerFuelTradingMyAuctionsPage />)}</Protected>,
  },
  {
    path: '/supplier/fuel-trading',
    element: <Protected>{getDefaultLayout(<SupplierFuelTradingDashboard />)}</Protected>,
  },
  {
    path: '/supplier/my-auctions',
    element: <Protected>{getDefaultLayout(<AuctionsSupplier />)}</Protected>,
  },
  {
    path: '/fuel-trading/orders-in-progress',
    element: <Protected>{getDefaultLayout(<FuelTradingOrdersInProgressPage />)}</Protected>,
  },
  {
    path: '/fuel-trading/cancelled',
    element: <Protected>{getDefaultLayout(<FuelTradingOrdersCancelledPage />)}</Protected>,
  },
  {
    path: '/fuel-trading/disputed',
    element: <Protected>{getDefaultLayout(<FuelTradingOrdersDisputedPage />)}</Protected>,
  },
  {
    path: '/fuel-trading/order-completed',
    element: <Protected>{getDefaultLayout(<FuelTradingCompletedOrdersPage />)}</Protected>,
  },
  {
    path: '/fuel-trading/buyer-order-funded',
    element: <Protected>{getAuthLayout(<BuyerOrderFunded />)}</Protected>,
  },
  {
    path: '/fuel-trading/buyer-shipping-funded',
    element: <Protected>{getAuthLayout(<BuyerShippingFunded />)}</Protected>,
  },
  {
    path: '/support-requests',
    element: <Protected>{getDefaultLayout(<SupportRequestsPage />)}</Protected>,
  },
  {
    path: '/support-requests/:id',
    element: <Protected>{getDefaultLayout(<SupportRequestDetailPage />)}</Protected>,
  },
  {
    path: '/faq',
    element: <Protected>{getDefaultLayout(<FAQPage />)}</Protected>,
    children: [
      {
        path: ':slug',
        element: <FAQDetailPage />,
      },
    ],
  },
  {
    path: '*',
    element: getDefaultLayout(<NotFoundPage />),
  },
  // {
  //   path: '/fuel-trading/transaction-history',
  //   element: <Protected>{getDefaultLayout(<SupplierTransactionHistory />)}</Protected>,
  // },
  // {
  //   path: '/fuel-trading/past-auctions-supplier',
  //   element: <Protected>{getDefaultLayout(<SupplierFuelTradingPastAuction />)}</Protected>,
  // },
])