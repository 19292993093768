import { Outlet, useParams } from 'react-router-dom'
import faqData from 'src/utils/faq-data'
import { useNavigate } from 'react-router-dom'

const FAQPage = () => {
  const navigate = useNavigate()
  const { slug } = useParams()

  if (!slug) {
    navigate(`/faq/${faqData[0].subsections[0].slug}`)
  }

  return (
    <div className="container mt-5">
      <div className="flex flex-row">
        <div className="xl:w-[305px] 2xl:w-[425px]">
          <h1 className="mb-2.5 font-bold ">Topics</h1>
          <div className="flex flex-col gap-2.5">
            <div>
              {faqData.map((section) => (
                <div key={section.id}>
                  <div>{section.name}</div>
                  <div className="flex flex-col">
                    {section.subsections.map((subSection) => {
                      return (
                        <a
                          onClick={() => navigate(`/faq/${subSection.slug}`)}
                          className="text-[#0077B6] cursor-pointer ml-2 text-sm"
                        >
                          {subSection.name}
                        </a>
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default FAQPage
