import { Badge } from 'src/components/ui/badge'
import { Button } from 'src/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'src/components/ui/card-default'
import { format } from 'date-fns'
import { Textarea } from 'src/components/ui/textarea'
import { ScrollArea } from 'src/components/ui/scroll-area'
import { Separator } from 'src/components/ui/separator'
import { useParams } from 'react-router-dom'
import { toast } from 'src/components/ui/use-toast'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { CalendarIcon, MessageSquareIcon, UserIcon } from 'lucide-react'
import { useState } from 'react'
import ResolveBtn from 'src/pages/support-requests/resolve-btn'

type ISupportRequestDetail = {
  id: string
  category: string
  issue: string
  description: string
  user: {
    id: string
    firstName: string
    lastName: string
    userType: string
    supportRequests: {
      id: string
      category: string
      issue: string
    }[]
  }
  createdAt: string
  updatedAt: string
  isResolved: boolean
  priority: 'HIGH' | 'MEDIUM' | 'LOW'
  assignedTo: {
    id: string
    name: string
    email: string
  }
  supportRequestMessages: {
    id: string
    message: string
    createdAt: string
    senderUser: {
      id: string
      firstName: string
      lastName: string
      userType: string
    }
    senderAdminUser: {
      id: string
      name: string
      email: string
    }
  }[]
}

const SupportRequestDetailPage = () => {
  const { id } = useParams()
  const [newComment, setNewComment] = useState('')
  const queryClient = useQueryClient()
  const supportRequestQuery = useQuery({
    queryKey: ['supportRequests', id],
    queryFn: async () => {
      const token = localStorage.getItem('token')
      return axios.get<{ supportRequest: ISupportRequestDetail }>(`/api/support-requests/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    retryOnMount: false,
    refetchOnReconnect: false,
    enabled: !!id,
  })

  const addCommentMutation = useMutation({
    mutationFn: ({ id, message }: { id: number; message: string }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/support-requests/${id}/add-comment`,
        { message },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const priorityBadge = {
    LOW: 'outline',
    MEDIUM: 'secondary',
    HIGH: 'destructive',
  } as const

  const getMessageUserName = (message: {
    senderUser?: {
      id: string
      firstName: string | null
      lastName: string | null
    }
    senderAdminUser?: { id: string; name: string | null; email: string }
  }) => {
    if (message.senderUser) {
      return message.senderUser.firstName + ' ' + message.senderUser.lastName
    }
    if (message.senderAdminUser) {
      return <span className="text-primary">REgen Support</span>
    }
    return null
  }

  const handleAddComment = () => {
    if (!id) {
      return
    }
    if (newComment.trim()) {
      console.log(`New comment added: ${newComment}`)
      if (!newComment) {
        toast({ title: 'Comment cannot be empty' })
        return
      }
      addCommentMutation.mutate(
        {
          id: parseInt(id),
          message: newComment,
        },
        {
          onSuccess: (resp) => {
            toast({
              title: 'Comment added successfully',
            })
            queryClient.invalidateQueries({ queryKey: ['supportRequests'] })
            setNewComment('')
          },
          onError: (err) => {
            const axiosError = err as AxiosError<{ message: string }>
            toast({ title: axiosError.response?.data?.message ?? 'Something went wrong', variant: 'destructive' })
          },
        },
      )
    }
  }

  const supportRequest = supportRequestQuery.data?.data?.supportRequest

  if (!supportRequest) {
    return <div>Support Request not found</div>
  }

  return (
    <main className="flex-1 overflow-hidden bg-gray-100">
      <div className="container mx-auto px-6 py-8 h-full flex flex-col lg:flex-row lg:space-x-8">
        <div className="lg:w-1/2 flex flex-col overflow-hidden">
          <Card className="mb-6">
            <CardHeader>
              <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold mb-5">Support Request #{supportRequest.id} </h1>
                {supportRequest.isResolved ? null : <ResolveBtn expand="normal" supportRequestId={supportRequest.id} />}
              </div>
              <CardTitle>
                {supportRequest.category} ({supportRequest.issue}){' '}
                <Badge variant={priorityBadge[supportRequest.priority ?? 'LOW']}>
                  {supportRequest.priority} PRIORITY
                </Badge>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-700 mb-4">{supportRequest.description}</p>
              <div className="text-gray-600 grid grid-cols-2 gap-4 text-sm">
                <div className="flex items-center">
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  Created: {format(new Date(supportRequest.createdAt), 'dd MMM yyyy hh:mm aaa')}
                </div>
                <div className="flex items-center">
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  Updated: {format(new Date(supportRequest.updatedAt), 'dd MMM yyyy hh:mm aaa')}
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="flex-1 flex flex-col">
            <CardHeader>
              <CardTitle>Add Comment</CardTitle>
            </CardHeader>
            <CardContent className="flex-1 flex flex-col">
              <Textarea
                placeholder="Type your comment here..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="flex-1 mb-4 resize-none"
              />
              <div className="flex justify-between items-center">
                <div></div>
                <Button onClick={handleAddComment}>
                  <MessageSquareIcon className="w-4 h-4 mr-2" />
                  Add Comment
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="lg:w-1/2 flex flex-col overflow-hidden mt-6 lg:mt-0 h-[calc(100vh-160px)]">
          <Card className="flex-1 flex flex-col overflow-hidden">
            <CardHeader>
              <CardTitle>Timeline</CardTitle>
            </CardHeader>
            <CardContent className="flex-1 overflow-y-auto">
              <div className="space-y-4">
                <ScrollArea className="flex-1 pr-4">
                  {supportRequest.supportRequestMessages.map((item, index) => (
                    <div key={item.id} className="mb-4 last:mb-0">
                      <div className="flex items-start">
                        {/*<Avatar className="mr-4">*/}
                        {/*  <AvatarFallback>{getMessageUserName(item)?.[0] ?? ''}</AvatarFallback>*/}
                        {/*</Avatar>*/}
                        <div className="flex-1">
                          <div className="flex items-center justify-between space-x-2">
                            <span className="font-semibold">{getMessageUserName(item)}</span>
                            <span className="text-gray-500">
                              {format(new Date(item.createdAt), 'dd MMM yyyy hh:mm aaa')}
                            </span>
                          </div>
                          <p className="mt-4">{item.message}</p>
                        </div>
                      </div>
                      {index < supportRequest.supportRequestMessages.length - 1 && <Separator className="my-4" />}
                    </div>
                  ))}
                </ScrollArea>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </main>
  )
}

export default SupportRequestDetailPage
